import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialsList1Props {
	className?: string;
}

const socials: any = [
	{
		name: "Facebook",
		icon: (
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_563_46304)">
					<path
						d="M14.1172 24.4058H10.2738C9.63184 24.4058 9.10962 23.8835 9.10962 23.2416V14.5726H6.86731C6.22534 14.5726 5.70312 14.0502 5.70312 13.4084V9.69379C5.70312 9.05182 6.22534 8.5296 6.86731 8.5296H9.10962V6.66943C9.10962 4.82501 9.68878 3.2558 10.7843 2.13171C11.8848 1.0025 13.4227 0.405762 15.2318 0.405762L18.1629 0.410522C18.8038 0.411621 19.3251 0.933838 19.3251 1.57471V5.02368C19.3251 5.66565 18.803 6.18787 18.1613 6.18787L16.1877 6.1886C15.5859 6.1886 15.4326 6.30927 15.3998 6.34625C15.3458 6.40759 15.2816 6.58099 15.2816 7.05981V8.52942H18.0129C18.2186 8.52942 18.4178 8.58014 18.589 8.67572C18.9583 8.88208 19.1879 9.27228 19.1879 9.69397L19.1865 13.4086C19.1865 14.0502 18.6642 14.5724 18.0223 14.5724H15.2816V23.2416C15.2816 23.8835 14.7592 24.4058 14.1172 24.4058ZM10.5166 22.9988H13.8744V13.9427C13.8744 13.5141 14.2232 13.1655 14.6517 13.1655H17.7795L17.7808 9.93659H14.6515C14.223 9.93659 13.8744 9.58795 13.8744 9.1593V7.05981C13.8744 6.51013 13.9302 5.88501 14.3452 5.41516C14.8465 4.84717 15.6366 4.78162 16.1874 4.78162L17.9181 4.78088V1.81714L15.2307 1.81274C12.3233 1.81274 10.5166 3.67383 10.5166 6.66943V9.1593C10.5166 9.58777 10.168 9.93659 9.7395 9.93659H7.11011V13.1655H9.7395C10.168 13.1655 10.5166 13.5141 10.5166 13.9427V22.9988Z"
						fill="white"
					/>
				</g>
				<defs>
					<clipPath id="clip0_563_46304">
						<rect
							width="23.9531"
							height="24"
							fill="white"
							transform="translate(0.523438 0.452637)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		href: "https://www.facebook.com/SafariaaTravel",
	},
	{
		name: "Instagram",
		icon: (
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_563_46306)">
					<path
						d="M18.4766 0.452637H6.4769C3.17728 0.452637 0.477051 3.15286 0.477051 6.45249V18.4528C0.477051 21.7515 3.17728 24.4526 6.4769 24.4526H18.4766C21.7762 24.4526 24.4765 21.7515 24.4765 18.4528V6.45249C24.4765 3.15286 21.7762 0.452637 18.4766 0.452637ZM22.4764 18.4528C22.4764 20.6577 20.6828 22.4526 18.4766 22.4526H6.4769C4.27167 22.4526 2.4771 20.6577 2.4771 18.4528V6.45249C2.4771 4.24696 4.27167 2.45269 6.4769 2.45269H18.4766C20.6828 2.45269 22.4764 4.24696 22.4764 6.45249V18.4528Z"
						fill="white"
					/>
					<path
						d="M18.9775 7.45232C19.8059 7.45232 20.4775 6.78076 20.4775 5.95236C20.4775 5.12395 19.8059 4.45239 18.9775 4.45239C18.1491 4.45239 17.4775 5.12395 17.4775 5.95236C17.4775 6.78076 18.1491 7.45232 18.9775 7.45232Z"
						fill="white"
					/>
					<path
						d="M12.4764 6.45251C9.16208 6.45251 6.47656 9.13833 6.47656 12.4524C6.47656 15.7652 9.16208 18.4528 12.4764 18.4528C15.7898 18.4528 18.4763 15.7652 18.4763 12.4524C18.4763 9.13833 15.7898 6.45251 12.4764 6.45251ZM12.4764 16.4528C10.2675 16.4528 8.47661 14.6618 8.47661 12.4524C8.47661 10.2429 10.2675 8.45256 12.4764 8.45256C14.6853 8.45256 16.4762 10.2429 16.4762 12.4524C16.4762 14.6618 14.6853 16.4528 12.4764 16.4528Z"
						fill="white"
					/>
				</g>
				<defs>
					<clipPath id="clip0_563_46306">
						<rect
							width="24"
							height="24"
							fill="white"
							transform="translate(0.476562 0.452637)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		href: "https://www.instagram.com/safaria.travel",
	},
	{
		name: "Twitter",
		icon: (
			<svg
			width="25"
				height="25"
				id="Layer_1"
				fill="white"
				data-name="Layer 1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 1187.92 1215.59"
			>
				<path
					d="M1569.29,1544.46l-398-578.26-45-65.4L841.53,487.15l-23.59-34.28H468.07l85.32,124L932,1126.92l45,65.33,304.18,442,23.6,34.24h349.87Zm-223,44.85-316-459.15-45-65.36L618.61,532H776.35l296.56,430.88,45,65.36,386.17,561.06Z"
					transform="translate(-466.7 -452.87)"
				/>
				<polygon points="518.62 611.93 563.62 677.29 510.27 739.38 100.94 1215.59 0 1215.59 465.27 674.05 518.62 611.93" />
				<polygon points="1145.8 0 704.56 513.33 651.21 575.38 606.21 510.02 659.56 447.93 958.45 100.04 1044.86 0 1145.8 0" />
			</svg>
		),
		href: "https://x.com/Safaria_Travel",
	},
	{
		name: "Apple store",
		icon: (
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_563_46320)">
					<path
						d="M16.9756 0.452637C15.6961 0.541136 14.2006 1.36013 13.3291 2.42663C12.5341 3.39412 11.8801 4.83111 12.1351 6.2276C13.5331 6.2711 14.9776 5.43261 15.8146 4.34811C16.5976 3.33862 17.1901 1.91063 16.9756 0.452637Z"
						fill="white"
					/>
					<path
						d="M22.0319 8.50455C20.8034 6.96406 19.0769 6.07007 17.4464 6.07007C15.2939 6.07007 14.3834 7.10056 12.8879 7.10056C11.3459 7.10056 10.1745 6.07307 8.31296 6.07307C6.48447 6.07307 4.53749 7.19056 3.30299 9.10155C1.5675 11.7925 1.8645 16.852 4.67699 21.1615C5.68348 22.7035 7.02747 24.4375 8.78546 24.4525C10.35 24.4675 10.7909 23.449 12.9104 23.4385C15.0299 23.4265 15.4319 24.466 16.9934 24.4495C18.7529 24.436 20.1704 22.5145 21.1769 20.9725C21.8984 19.867 22.1669 19.3105 22.7264 18.0625C18.6569 16.513 18.0044 10.726 22.0319 8.50455Z"
						fill="white"
					/>
				</g>
				<defs>
					<clipPath id="clip0_563_46320">
						<rect
							width="24"
							height="24"
							fill="white"
							transform="translate(0.476562 0.452637)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		href: "https://apps.apple.com/us/app/telefreik/id6447812019",
	},
	{
		name: "Google play",
		icon: (
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_563_46327)">
					<path
						d="M5.71505 0.836462C4.77611 0.317491 3.67367 0.329491 2.74072 0.843962L13.6751 10.9309L17.3484 7.2576L5.71505 0.836462Z"
						fill="white"
					/>
					<path
						d="M1.64703 1.87598C1.38004 2.31245 1.22705 2.81192 1.22705 3.34139V21.5594C1.22705 22.0723 1.36654 22.5613 1.61853 22.9888L12.6144 11.9929L1.64703 1.87598Z"
						fill="white"
					/>
					<path
						d="M22.2184 9.94548L18.7146 8.01208L14.7773 11.9479L19.6026 16.3981L22.2199 14.9537C23.1619 14.4317 23.7258 13.4958 23.7258 12.4488C23.7243 11.4019 23.1619 10.4659 22.2184 9.94548Z"
						fill="white"
					/>
					<path
						d="M13.7168 13.0098L2.69092 24.0356C3.16939 24.3086 3.69436 24.4526 4.22233 24.4526C4.7323 24.4526 5.24527 24.3236 5.71475 24.0641L18.2195 17.163L13.7168 13.0098Z"
						fill="white"
					/>
				</g>
				<defs>
					<clipPath id="clip0_563_46327">
						<rect
							width="24"
							height="24"
							fill="white"
							transform="translate(0.476562 0.452637)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		href: "https://play.google.com/store/apps/details?id=com.teleferik",
	},
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
	const renderItem = (item: SocialType, index: number) => {
		return (
			<a
				href={item.href}
				className="group flex items-center justify-center space-x-2 text-3xl leading-none text-white hover:text-black ltr:mx-2 rtl:mx-2"
				key={index}
			>
				<span>{item.icon}</span>
			</a>
		);
	};

	return (
		<div
			className={`nc-SocialsList1 flex content-center items-center gap-0  `}
			data-nc-id="SocialsList1"
		>
			{socials.map(renderItem)}
		</div>
	);
};

export default SocialsList1;
