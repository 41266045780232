import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { AddressData } from "./ParentComponent";

interface AddressPreviewProps {
  address: AddressData;
  onEdit: () => void;
  onDelete: (id: number) => void;
}

const AddressPreview = ({ address, onEdit, onDelete }: AddressPreviewProps) => {
  const { t } = useTranslation();
  const mapContainerStyle = { width: "100%", height: "200px" };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://app.telefreik.com/api/transports/profile/address-book/${address.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        toast.success(t("address.deletedSuccess"));
        onDelete(address.id);
      }
    } catch (error) {
      toast.error(t("error"));
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-4 border border-gray-200  min-w-[300px] ">
      <div className="flex flex-col gap-5 md:flex-row">
        {/* Address Name */}
        <div className="flex flex-col gap-3 w-full md:w-[296px]">
          <div className="text-gray-600 text-base font-normal font-cairo">{t("address.name")} *</div>
          <div className="w-full h-[54px] relative border border-gray-300 rounded flex items-center px-3">
            <div className="text-gray-600 text-base font-normal font-cairo">{address.name}</div>
          </div>
        </div>
        {/* Address */}
        <div className="flex flex-col gap-3 w-full md:w-[296px]">
          <div className="text-gray-600 text-base font-normal font-cairo">{t("address.address")} *</div>
          <div className="w-full h-[54px] relative border border-gray-300 rounded flex items-center px-3">
            <div className="text-gray-600 text-base font-normal font-cairo">{address.notes}</div>
          </div>
        </div>
      </div>

      {/* Map */}
      <div className="h-48 w-full rounded-lg overflow-hidden mt-4 border border-gray-200">
        <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY!}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={{ lat: address.map_location.lat, lng: address.map_location.lng }}
            zoom={15}
          >
            <Marker position={{ lat: address.map_location.lat, lng: address.map_location.lng }} />
          </GoogleMap>
        </LoadScript>
      </div>

      {/* Buttons */}
      <div className="flex flex-col gap-2 md:flex-row justify-end mt-4">
        <button
          onClick={onEdit}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 text-sm font-medium"
        >
          {t("edit")}
        </button>
        <button
          onClick={handleDelete}
          className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200 text-sm font-medium"
        >
          {t("delete")}
        </button>
      </div>
    </div>
  );
};

export default AddressPreview;
