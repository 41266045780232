import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation
import { createFirstTrip, createReturnTrip, createOneRoundTrip, createPayment, getSeats } from "api";
import { useQuery } from "react-query";
import { showApiErrorMessages } from "utils";
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import PaymentDetailsModal from "shared/payment";
import Layout from "shared/FaceBook/Layout";
import i18next from "i18next";
import isSeatNumberExists from "utils/isNumbe4rexistsinarr";
import { TazcraBus } from "shared/TazkaraBus";
import { PrimeBus } from "shared/primeBus";
import { ComfortBus } from "shared/ComfortBus";
import { BusinessBus } from "shared/businessBus";
import { EconomyBus } from "shared/EconomyBus";
import { FirstTenBus } from "shared/firstTen";
import { Bus } from "shared/bus";

export interface CheckOutPageProps {
  className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const { search, pathname } = useLocation(); // Use useLocation to get search and pathname
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State variables
  const [date, setDate] = useState<string>("");
  const [travelTo, setTravelTo] = useState<string>("");
  const [travelFrom, setTravelFrom] = useState<string>("");
  const [cityTo, setCityTo] = useState<string>("");
  const [cityFrom, setCityFrom] = useState<string>("");
  const [seats, setSeats] = useState<any[]>([]);
  const [id, setId] = useState<string>("");
  const [priceData, setPriceData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<number | string>("");
  const [iframe, setIframe] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [type, setType] = useState<string | null>("");
  const [selectedSeatsList, setSelectedSeatsList] = useState<any>({});
  const [seatsType, setSeatsType] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [salonCols, setSalonCols] = useState<number>(0);
  const [salonRows, setSalonRows] = useState<number>(0);
  const [companyName, setCompanyName] = useState<string | null>(localStorage.getItem("current_company_name"));

  // Fetch data from localStorage
  const dropOffLocationType = localStorage.getItem("dropOffLocationType");
  const isFirstTripFinished = localStorage.getItem("isFirsftTripFinshed");
  const travleFromBus = JSON.parse(localStorage.getItem("travle_from_bus") || "{}");
  const travleToBus = JSON.parse(localStorage.getItem("travle_to_bus") || "{}");

  // Parse URL search params
  useEffect(() => {
    if (search) {
      const data = search.slice(1).split("/");
      setDate(data[0]);
      setTravelFrom(data[1]);
      setTravelTo(data[2]);
      setId(data[3]);
      setCityFrom(data[5]);
      setCityTo(data[6]);
      setType(data[7]);
      setSeatsType(data[8]);

      sessionStorage.setItem("path", `${pathname}?${data.join("/")}`); // Use pathname from useLocation
    }
  }, [search, pathname]); // Add pathname to dependency array

  // Fetch seats data
  const { data } = useQuery(
    ["getSeats", travelFrom, travelTo, id],
    () => getSeats({ from_location_id: travelFrom, to_location_id: travelTo, id, date, cityFrom, cityTo }),
    {
      onSuccess: (response) => {
        if (companyName !== '0 ') {
          setSalonCols(response.data.data.salon.columns);
          setSalonRows(response.data.data.salon.rows);
          setSeats(response.data.data.seats_map);
        } else if (response?.data?.data?.length) {
          setSeats(response.data.data);
        }
      },
      onError: (errors: any) => {
        if (errors.response?.data?.errors) {
          showApiErrorMessages(errors.response.data.errors);
        } else {
          toast.error(errors.response?.data?.message);
        }
        if (errors.response?.status === 401) {
          navigate("/login");
        }
      },
    }
  );

  // Handle seat selection
  const handleSeatSelection = (seatId: string) => {
    setSelectedSeatsList((prev: any) => ({
      ...prev,
      [seatId]: !prev[seatId],
    }));
  };

  // Create first trip
  const createFirsttrip = async () => {
    const seatsList = prepareSeatsList();
    if (seatsList.length) {
      setLoading(true);
      const data = {
        round: 1,
        boarding: {
          trip_id: id,
          from_city_id: cityFrom,
          to_city_id: cityTo,
          from_location_id: travelFrom,
          to_location_id: travelTo,
          date,
          seats: seatsList,
        },
      };

      try {
        const res = await createFirstTrip(data);
        setOrderId(res.data.data.gateway_order_id);
        setPriceData(res.data.data);
        toast.success(t(res.data.message));
        localStorage.setItem("bus_First_Ticket", JSON.stringify(res.data.data));
        navigate(`/listing-bus?${endDate}/${travleFromBus.id}/${travleToBus.id}/${travleFromBus.name_en}/${travleToBus.name_en}/first`);
      } catch (err: any) {
        handleApiError(err);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error(t("selectSeatPlz"));
    }
  };

  // Create return trip
  const createReturnTicket = async () => {
    const seatsList = prepareSeatsList();
    if (seatsList.length) {
      setLoading(true);
      const data = {
        trip_id: id,
        from_city_id: cityFrom,
        to_city_id: cityTo,
        from_location_id: travelFrom,
        to_location_id: travelTo,
        date: endDate,
        seats: seatsList,
      };

      try {
        const res = await createReturnTrip(data, id);
        setOrderId(res.data.data.gateway_order_id);
        setPriceData(res.data.data);
        toast.success(t(res.data.message));
        localStorage.setItem("bus_Return_Ticket", JSON.stringify(res.data));
        navigate("/bus-trip/twoRound/summary");
      } catch (err: any) {
        handleApiError(err);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error(t("selectSeatPlz"));
    }
  };

  // Create one-round trip
  const createOneRound = async () => {
    const seatsList = prepareSeatsList();
    if (seatsList.length) {
      setLoading(true);
      const data = {
        round: 1,
        boarding: {
          trip_id: id,
          from_city_id: cityFrom,
          to_city_id: cityTo,
          from_location_id: travelFrom,
          to_location_id: travelTo,
          date,
          seats: seatsList,
        },
      };

      try {
        const res = await createOneRoundTrip(data);
        setOrderId(res.data.data.gateway_order_id);
        setPriceData(res.data.data);
        toast.success(res.data.message);
        localStorage.setItem("bus_Ticket", JSON.stringify(res.data.data));
        navigate("/bus-trip/oneRound/summary");
      } catch (err: any) {
        handleApiError(err);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error(t("selectSeatPlz"));
    }
  };

  // Prepare seats list for API
  const prepareSeatsList = () => {
    const seatsList: any[] = [];
    for (const property in selectedSeatsList) {
      seats.forEach((item: any) => {
        if (+item.id === +selectedSeatsList[property] && !isSeatNumberExists(seatsList, item.id)) {
          seatsList.push({ seat_id: item.id, seat_type_id: item.seat_type_id });
        }
      });
    }
    return seatsList;
  };

  // Handle API errors
  const handleApiError = (err: any) => {
    if (err.response?.data?.errors) {
      showApiErrorMessages(err.response.data.errors);
    } else {
      toast.error(err.response?.data?.message);
    }
    if (err.response?.status === 401) {
      navigate("/login");
    }
  };

  // Render bus component based on type
  const renderBusComponent = () => {
    if (companyName !== "0a"  ) {
      return (
        <TazcraBus
          seats={seats}
          busDirection="rtl"
          selected={selectedSeatsList}
          setSelected={setSelectedSeatsList}
          rows={salonRows}
          cols={salonCols}
        />
      );
    } else {
      switch (seatsType) {
        case "Prime_Mix":
          return <PrimeBus seats={seats} selected={selectedSeatsList} setSelected={setSelectedSeatsList} />;
        case "Comfort":
          return <ComfortBus seats={seats} selected={selectedSeatsList} setSelected={setSelectedSeatsList} />;
        case "Business40":
          return <BusinessBus seats={seats} selected={selectedSeatsList} setSelected={setSelectedSeatsList} />;
        case "Economy":
          return <EconomyBus seats={seats} selected={selectedSeatsList} setSelected={setSelectedSeatsList} />;
        case "First10":
          return <FirstTenBus seats={seats} selected={selectedSeatsList} setSelected={setSelectedSeatsList} />;
        default:
          return <Bus seats={seats} selected={selectedSeatsList} setSelected={setSelectedSeatsList} />;
      }
    }
  };

  return (
    <Layout>
      <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
        <div className="flex h-[15vh] w-full flex-col bg-[#0074C3]">
          {/* Top navigation */}
          <div className="relative mx-auto flex h-[50px] w-[80vw] flex-row justify-between text-white">
            <button className="flex w-[4vw] items-center justify-around" onClick={() => navigate(-1)}>
              <svg width="12" height="12" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.75 16.1538L1.25 8.65381L8.75 1.15381" stroke="white" strokeWidth="2" strokeLinejoin="round" />
              </svg>
              <span className="mt-1">{t("back")}</span>
            </button>
          </div>

          {/* Center data */}
          <div className="flex flex-col items-center justify-center pb-10 text-white">
            <span className="absolute z-0 h-[20vh] w-[20vh] rotate-45 rounded bg-[#0074C3]"></span>
            <h3 className="z-10">
              {i18next.language === "en" ? travleFromBus.name_en : travleFromBus.name_ar} -{" "}
              {i18next.language === "en" ? travleToBus.name_en : travleToBus.name_ar}
            </h3>
            <h3 className="z-10">
              {date} . {seatsType}
            </h3>
          </div>
        </div>

        <main className="flex h-full w-full flex-col">
          <div className="container mb-24 mt-11 flex w-full flex-col lg:mb-32 lg:flex-row max-2xl:mt-28 2xl:mt-28 mx-auto justify-center">
            <div className="items-start flex justify-between lg:w-3/5 lg:pr-10 xl:w-1/1">{renderBusComponent()}</div>
          </div>

          <div className="container max-md:w-[80%] max-sm:translate-y-[-10px] sm:w-[80%] md:w-[80%] md:translate-y-[-70px] lg:w-[95%] lg:translate-y-[-100px] xl:w-[95%]">
            <div className="">{!orderId && <ButtonPrimary loading={loading} onClick={dropOffLocationType === "oneWay" ? createOneRound : isFirstTripFinished === "first" ? createReturnTicket : createFirsttrip}>{t(dropOffLocationType === "oneWay" ? "go to summary" : isFirstTripFinished === "first" ? "go to summary" : "book & go to 2ed trip")}</ButtonPrimary>}</div>
          </div>
        </main>

        <PaymentDetailsModal iframe={iframe} isOpenProp={isOpen} onCloseModal={() => setIsOpen(false)} />
      </div>
    </Layout>
  );
};

export default CheckOutPage;