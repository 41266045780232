import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import React, { FC, useEffect, useMemo, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useRoutes } from "react-router-dom";
import { searchTrip } from "api";
import { useQuery } from "react-query";
import { showApiErrorMessages } from "utils";
import { toast } from "react-toastify";
import { BriefcaseIcon } from "@heroicons/react/24/solid";
import homeBg2 from "images/homeBg3.jpg";
import busIcon from './bus.svg'; // Adjust the path according to your project structure
import { forEach, set, values } from "lodash";
import SeatCard from "components/SeatCard/SeatCard";
import OpratorCard from "components/OpratorCard/OpratorCard";
import DepartureCard from "components/departureCard/DepartureCard";
import PriceCard from "components/PriceCard/PriceCard";
import BusTimeCard from "components/BusTimeCard/BusTimeCard";
// import TripAnalyzer from "./TripAnalys";
import classes from "components/departureCard/DepartureCard.module.css";
import BusResultsFilters from "./BusResultsFilters";
import removeDuplicates from "utils/removeDuplicates";
import changeFromHHmmFormatToDateFormate from "utils/changeFromHHmmFormatToDateFormate";
import { getDuration } from "utils/getDuration";
import refactorData from "utils/refactorData";
import Styled from "./page.module.css";
import { Filters } from "./Filters";
// filter components
import { DeptureTime } from "./filters/DeptureTime";
import FilterClass from "./filters/FilterClass";
import Layout from "shared/FaceBook/Layout";
export interface RefactoredData {
	classes: string;
	travel_from: string;
	travel_to: string;
	gateway_id: string;
	arrival_at: string;
	travel_at: string;
	prices_start_with: number;
}

export interface City {
	id: number;
	name: string;
}
export interface ListingFlightsPageProps {
	className?: string;
}
const ListingBusPage: FC<ListingFlightsPageProps> = ({ className = "" }) => {
	const { t } = useTranslation();
	const { search } = useLocation();
	const [date, setDate] = useState<string>("");

	const [travelTo, setTravelTo] = useState<string>("");
	const [travelFrom, setTravelFrom] = useState<string>("");
	const [trips, setTrips] = useState<any>([]);
	const [refactoredTrips, setRefactoredTrips] = useState<any>([]);
	const [filterdTrips, setFilterdTrips] = useState<any>([]);
	const [stationFrom, setStationFrom] = useState<any>([]);
	const [operators, setOperators] = useState<any>([]);
	const [stationTo, setStationTo] = useState<any>([]);
	const [city, setCity] = useState<string>("");
	const [page, setPage] = useState<number>(1);
	const [cityFrom, setCityFrom] = useState<any>("");
	const [paginationStatus, setPaginationStatus] = useState<boolean>(true);
	const [filterStation, setFilterStation] = useState<string>("");
	const [filterCompany, setFilterCompany] = useState<string>("");
	const [filterFunction, setFilterFunction] = useState<Function>(
		(item: any) => true,
	);
	const [originalTrips, setOriginalTrips] = useState<any>([]);
	const [displayableData, setDisplayableData] = useState<any>([]);
	const [first, setFirst] = useState("");
	// filters hooks from api
	const [deptureTimeFromApi, set_depture_time_api] = useState<any>([]);
	const [arrivalTimeAtApi, setarrivalTimeAtApi] = useState<any>([]);
	const [filterToStation, setFilerToStation] = useState<string>("");
	// after filtration
	const [FinalTrips, SetFinalTrips] = useState<any>([]);
	window.localStorage.setItem("isFirsftTripFinshed", first);

	useEffect(() => {
		if (!!search) {
			const data = search.slice(1).split("/");
			setDate(data?.[0]);
			setTravelTo(data?.[1]);
			setTravelFrom(data?.[2]);
			const re = /\%20/gi;
			const from = data?.[3].replace(re, "");
			const to = data?.[4].replace(re, "");
			setCity(from);
			setCityFrom(to);
			setFirst(data?.[5]);
			setTrips([]);
			setPage(1);
		}
	}, [search]);
	const [loading, setLoading] = useState<boolean>(false);

	const getTripsBus = async () => {
		setLoading(true);

		if (
			travelTo !== undefined &&
			travelTo !== "undefined" &&
			travelFrom !== "undefined" &&
			travelFrom !== undefined &&
			!!travelFrom &&
			!!travelTo
		) {
			await searchTrip({ date, city_to: travelTo, city_from: travelFrom }, page)
				.then((res: any) => {
					if (res?.data?.data?.length) {
						
						const data = refactorData([...res?.data?.data]);
						setTrips((prev: any) => [...prev, ...res?.data?.data]);
						setDisplayableData((prev: any) => [...prev, ...data]);
						setOriginalTrips((prev: any) => [...prev, ...res?.data?.data]);
						SetFinalTrips((prev: any) => [...prev, ...res?.data?.data]);
						// set_depture_time_api((prev : any) => [...prev , ...res?.data?.filters?.depart_times])
						// setarrivalTimeAtApi((prev : any ) => [...prev , ...res?.data?.filters?.arrival_times])
						if (res?.data?.pagination?.nextPageUrl !== null) {
							setPaginationStatus(true);
						} else {
							setPaginationStatus(false);
						}
					}
					setLoading(false);
				})
				.catch((errors: any) => {
					console.log(errors)
					setLoading(false);
						
				});
		}
	};
	const loadingContainer = () => {
		return (
			<div className="mt-4 flex flex-col w-full   gap-6 max-sm:w-[80vw]  ">
				<div className="relative w-full overflow-hidden rounded-lg bg-white p-4 shadow hover:shadow-md">
					<div className="flex animate-pulse flex-col">
						<div className="h-52 w-full rounded bg-gray-200"></div>
						<div className="mt-5 flex flex-col">
							<div className="h-5 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-10/12 rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-8/12 rounded bg-gray-200"></div>
						</div>

						<div className="mt-5 grid grid-cols-2 gap-x-2 gap-y-1">
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
						</div>

						<div className="mt-5 flex items-center">
							<div>
								<div className="h-10 w-10 rounded-full bg-gray-200"></div>
							</div>
							<div className="ml-3 flex w-full justify-between">
								<div className="h-3 w-5/12 rounded bg-gray-200"></div>
								<div className="h-3 w-2/12 rounded bg-gray-200"></div>
							</div>
						</div>
					</div>
				</div>

				<div className="relative w-full overflow-hidden rounded-lg bg-white p-4 shadow hover:shadow-md">
					<div className="flex animate-pulse flex-col">
						<div className="h-52 w-full rounded bg-gray-200"></div>
						<div className="mt-5 flex flex-col">
							<div className="h-5 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-10/12 rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-8/12 rounded bg-gray-200"></div>
						</div>

						<div className="mt-5 grid grid-cols-2 gap-x-2 gap-y-1">
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
						</div>

						<div className="mt-5 flex items-center">
							<div>
								<div className="h-10 w-10 rounded-full bg-gray-200"></div>
							</div>
							<div className="ml-3 flex w-full justify-between">
								<div className="h-3 w-5/12 rounded bg-gray-200"></div>
								<div className="h-3 w-2/12 rounded bg-gray-200"></div>
							</div>
						</div>
					</div>
				</div>

				<div className="relative w-full overflow-hidden rounded-lg bg-white p-4 shadow hover:shadow-md">
					<div className="flex animate-pulse flex-col">
						<div className="h-52 w-full rounded bg-gray-200"></div>
						<div className="mt-5 flex flex-col">
							<div className="h-5 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-10/12 rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-8/12 rounded bg-gray-200"></div>
						</div>

						<div className="mt-5 grid grid-cols-2 gap-x-2 gap-y-1">
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
						</div>

						<div className="mt-5 flex items-center">
							<div>
								<div className="h-10 w-10 rounded-full bg-gray-200"></div>
							</div>
							<div className="ml-3 flex w-full justify-between">
								<div className="h-3 w-5/12 rounded bg-gray-200"></div>
								<div className="h-3 w-2/12 rounded bg-gray-200"></div>
							</div>
						</div>
					</div>
				</div>

				<div className="relative w-full overflow-hidden rounded-lg bg-white p-4 shadow hover:shadow-md">
					<div className="flex animate-pulse flex-col">
						<div className="h-52 w-full rounded bg-gray-200"></div>
						<div className="mt-5 flex flex-col">
							<div className="h-5 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-10/12 rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-8/12 rounded bg-gray-200"></div>
						</div>

						<div className="mt-5 grid grid-cols-2 gap-x-2 gap-y-1">
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
							<div className="mt-2 h-3 w-full rounded bg-gray-200"></div>
						</div>

						<div className="mt-5 flex items-center">
							<div>
								<div className="h-10 w-10 rounded-full bg-gray-200"></div>
							</div>
							<div className="ml-3 flex w-full justify-between">
								<div className="h-3 w-5/12 rounded bg-gray-200"></div>
								<div className="h-3 w-2/12 rounded bg-gray-200"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	useEffect(() => {
		if (
			travelTo !== undefined &&
			travelTo !== "undefined" &&
			travelFrom !== "undefined" &&
			travelFrom !== undefined &&
			!!travelFrom &&
			!!travelTo
		) {
			getTripsBus();
			setRefactoredTrips(travelData);
		}
	}, [travelTo, travelFrom, page, date]);

	let travelData: any[] = refactorData(trips);
	let travelDataImmutable: any[] = useMemo(() => refactorData(trips), [trips]);

	const T_T = parseInt(travelTo);
	const T_F = parseInt(travelFrom);

	const companyHandler = (company: any) => {
		setFilterCompany(company);
	};
	function handleBackClick() {
		window.history.back();
	  }

	return (
		<Layout>
		<div
			className={` relative overflow-hidden bg-[#E8ECF2] ${className}`}
			data-nc-id="ListingFlightsPage"
		>
			<Helmet>
				<title>Safaria For Booking Travels</title>
			</Helmet>

			<div>
				<div
					className="m-0 block  w-[100%]  p-0 "
					style={{
						objectFit: "contain",
					}}
				>
					<img
						src={homeBg2}
						className="mb-0 mt-0  w-[100%] object-cover p-0  max-sm:h-[80px] max-sm:w-[100%]  lg:h-[30vh]"
					></img>
				</div>

				<div className=" flex min-h-[300px] flex-col items-center  max-lg:mt-10 max-sm:relative  max-sm:mt-0 sm:mt-5 lg:mt-10 ">
					{loading && page === 1 && (
						<div className="my-4 flex  w-full justify-center max-sm:hidden">
							<svg
								width="80"
								height="80"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12,23a9.63,9.63,0,0,1-8-9.5,9.51,9.51,0,0,1,6.79-9.1A1.66,1.66,0,0,0,12,2.81h0a1.67,1.67,0,0,0-1.94-1.64A11,11,0,0,0,12,23Z"
									fill="#000000"
								>
									<animateTransform
										attributeName="transform"
										type="rotate"
										dur="0.75s"
										values="0 12 12;360 12 12"
										repeatCount="indefinite"
									/>
								</path>
							</svg>
						</div>
					)}
					<div
						className={`flex w-[95vw] flex-row  justify-evenly max-sm:translate-y-6 ${Styled.BusHome_container}`}
					>
						<div
							className={` mx-2 p-2 max-md:w-[40%] lg:w-[30%]    ${
								Styled.Fillter_component
							}  ${
								travelData.length <= 0
									? "min-h-[100px] rounded-md bg-white shadow "
									: ""
							} max-md:hidden max-sm:hidden sm:hidden  md:block lg:block xl:block`}
						>
							<div className=" flex w-[85%] items-center justify-between rtl:mr-10  max-sm:hidden ">
								{!loading && travelData.length > 0 && (
									<div className="flex items-center justify-start">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
										>
											<path
												d="M10.5 6H20.25M10.5 6C10.5 6.39782 10.342 6.77936 10.0607 7.06066C9.77936 7.34196 9.39782 7.5 9 7.5C8.60218 7.5 8.22064 7.34196 7.93934 7.06066C7.65804 6.77936 7.5 6.39782 7.5 6M10.5 6C10.5 5.60218 10.342 5.22064 10.0607 4.93934C9.77936 4.65804 9.39782 4.5 9 4.5C8.60218 4.5 8.22064 4.65804 7.93934 4.93934C7.65804 5.22064 7.5 5.60218 7.5 6M7.5 6H3.75M10.5 18H20.25M10.5 18C10.5 18.3978 10.342 18.7794 10.0607 19.0607C9.77936 19.342 9.39782 19.5 9 19.5C8.60218 19.5 8.22064 19.342 7.93934 19.0607C7.65804 18.7794 7.5 18.3978 7.5 18M10.5 18C10.5 17.6022 10.342 17.2206 10.0607 16.9393C9.77936 16.658 9.39782 16.5 9 16.5C8.60218 16.5 8.22064 16.658 7.93934 16.9393C7.65804 17.2206 7.5 17.6022 7.5 18M7.5 18H3.75M16.5 12H20.25M16.5 12C16.5 12.3978 16.342 12.7794 16.0607 13.0607C15.7794 13.342 15.3978 13.5 15 13.5C14.6022 13.5 14.2206 13.342 13.9393 13.0607C13.658 12.7794 13.5 12.3978 13.5 12M16.5 12C16.5 11.6022 16.342 11.2206 16.0607 10.9393C15.7794 10.658 15.3978 10.5 15 10.5C14.6022 10.5 14.2206 10.658 13.9393 10.9393C13.658 11.2206 13.5 11.6022 13.5 12M13.5 12H3.75"
												stroke="#69696A"
												
												strokeLinejoin="round"
											/>
										</svg>
										<span className="ml-3 rtl:mr-3">{t("filter")}</span>
									</div>
								)}
								{loading
									? loadingContainer()
									: travelData.length <= 0 && !loading
									? "no data to filter"
									: ""}
							</div>

							{/* need to add steps */}
							{!loading && travelData.length > 0 && (
								<div className="flex w-full flex-col items-center   ">
									<FilterClass
										refactoredData={travelDataImmutable}
										setData={setDisplayableData}
										displayedData={displayableData}
										is_mobile={false}
									/>
									{/* <BusResultsFilters className="flex-1 mr-10" 
											RefactoredData={travelDataImmutable} isLoading={loading}
											TravleFrom = {T_F} 
											TravleTo = {T_T}
											setData={setDisplayableData}
											
											/> */}
								</div>
							)}
						</div>

						<div
							className={` ${Styled.Min_filter} my-0 mt-0  mb-4 h-[70px] w-[100vw]  max-sm:block sm:block  md:hidden lg:hidden z-50`}
						>
							{!loading && travelData.length > 0 && (
								<div className="h-full w-[100%] bg-white  ">
									<FilterClass
										refactoredData={travelDataImmutable}
										setData={setDisplayableData}
										displayedData={displayableData}
										is_mobile={true}
									/>
								</div>
							)}
						</div>

						<div className="md:w-full lg:w-[70%]  ">
							{trips.length > 0 ? (
								<SectionGridFilterCard
									trips={trips}
									city={city}
									isLoading={loading}
									className="pb-24 lg:pb-28"
									date={date}
									refavtord_data={displayableData}
									filterStation={filterStation}
									travelFrom={travelFrom}
									travelTo={travelTo}
									cityFrom={cityFrom}
									setPage={() => setPage(page + 1)}
									paginationStatus={paginationStatus}
								/>
							) : loading ? (
								loadingContainer()
							) : (
								loading && "No trips found at this day"
							)}
							
							{!loading && travelData.length === 0 && (
								    <div className="flex flex-col items-center justify-center h-[100%] m-auto  w-full  text-center text-gray-500 max-sm:h-[300px] max-sm:-mt-48 mb-5">
									      <img src={busIcon} alt="No trips" className="w-32 h-32 mb-4  " />

								      <p className="text-xl text-gray-900 m-auto">{t('NoTrip')}</p>
									  <button
            className="flex w-[4vw] items-center justify-around"
            onClick={handleBackClick}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.75 16.1538L1.25 8.65381L8.75 1.15381"
                stroke="white"
                
                strokeLinejoin="round"
              />
            </svg>
            <span className="mt-1">{t("back")}</span>
          </button>
								</div>
							)}
						</div>
					</div>

					{/* SECTION */}
				</div>
			</div>
		</div>
		</Layout>
	);
};

export default ListingBusPage;
