import React, { FC, useContext, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAddSignup } from "hooks/DataSend/useSignUp";
import useInput from "hooks/useInput";
import { AppContext } from "components/context/AppContext";
import EgyptPhoneInput from "components/EditProfile/EgyptPhoneInput";
import classes from "./PageSignUp.module.css";
import i18n from "i18n";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const { token } = useContext(AppContext);
  const navigate = useNavigate();

  // States for dynamic form handling
  const [phone, setPhone] = useState(localStorage.getItem("phone") || "");
  const [phoneHasError, setPhoneHasError] = useState(false);
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  // Form validation states
  const { value: password, isValid: passwordIsValid, hasError: passwordHasError, inputBlurHandler: passwordBlur, valueChangeHandler: passwordChange } = useInput((value: string) => value.length >= 6);
  const { value: confirmPassword, isValid: confirmPasswordIsValid, hasError: confirmPasswordHasError, inputBlurHandler: confirmPasswordBlur, valueChangeHandler: confirmPasswordChange } = useInput((value: string) => value === password);
  const { value: name, isValid: nameIsValid, hasError: nameHasError, inputBlurHandler: nameBlur, valueChangeHandler: nameChange } = useInput((value: string) => value.trim() !== "");

  // Add signup mutation
  const { mutate } = useAddSignup();

  // Screen resize handler
  useEffect(() => {
    const handleResize = () => setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Validation of form
  const formIsValid = !phoneHasError && nameIsValid && passwordIsValid && confirmPasswordIsValid;

  const handlePhoneChange = (value: string) => {
    setPhone(value);
    localStorage.setItem("phone", value);
  };
  

  const handlePhoneBlur = () => setPhoneHasError(phone.length <= 9);

  
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
	event.preventDefault();
	console.log("Form submit handler called");
	if (formIsValid) {
	  console.log("Form is valid");
	  const data = new FormData();
	  data.append("mobile", phone);
	  data.append("name", name);
	  data.append("firebase_token", "sample-firebase-token");
	  data.append("password", password);
	  data.append("password_confirmation", confirmPassword);
	  data.append("os_system", "website"); // Add appropriate value
	  data.append("os_version", "v2"); // Add appropriate value
	  data.append("phonecode", "+2"); // Add appropriate value
	  console.log("Form data:", data);
	  mutate(data); // Calling the mutation to sign up the user
	  localStorage.setItem("name", name);
	} else {
	  console.log("Form is not valid");
	}
  };
  
  const handleButtonClick = () => {
	console.log("Button click handler called");
	// Perform form submission logic or call handleFormSubmit directly
	if (formIsValid) {
	  console.log("Form is valid");
	  const data = new FormData();
	  data.append("mobile", phone);
	  data.append("name", name);
	  data.append("firebase_token", "sample-firebase-token");
	  data.append("password", password);
	  data.append("password_confirmation", confirmPassword);
	  data.append("os_system", "website"); // Add appropriate value
	  data.append("os_version", "v2"); // Add appropriate value
	  data.append("phonecode", "20"); // Add appropriate value
	  console.log("Form data:", data);
	  console.log("Form data:", data);
	  mutate(data); // Calling the mutation to sign up the user
	  localStorage.setItem("name", name);
	} else {
	  console.log("Form is not valid");
	}
  };
  const currentLanguage: string = i18n.language;

  return (
    <div className={`nc-PageLogin ${className} relative mb-[20%] h-[80vh] bg-gradient-to-r from-[#2E74BD] to-[#2B356E] max-sm:mb-[35%]`}>
      <Helmet>
        <title>
          {currentLanguage === "ar"
            ? "احجز أفضل عروض الطیران والمواصلات في مصر - سفریة"
            : "Book flight and transportation deals in Egypt - Safaria"}
        </title>
      </Helmet>
      <div className="container absolute top-20 left-2 right-2 w-[80vw] rounded-[16px] bg-white shadow-lg transition-[0.5s] lg:mb-32">
        <div className="mt-[42px] flex justify-center">
          <div className="flex h-[50px] w-[170px] items-center justify-center rounded-[24px]">
            <span className="block text-center dark:text-neutral-300">
              <Link to="/login"><span>{t("login")}</span></Link>
            </span>
          </div>
          <div className="flex h-[50px] w-[170px] items-center justify-center rounded-[24px] bg-[#0074C3] text-center text-white">
            <span className="block text-center dark:text-neutral-300">
              <Link to="/signup"><span>{t("Sign up")}</span></Link>
            </span>
          </div>
        </div>

        <div className="mx-auto max-w-md space-y-14">
          <form className="grid grid-cols-1 gap-4" onSubmit={handleFormSubmit}>
            <div className="flex flex-col items-center">
              <div className="mb-4 w-full">
                <span className="block text-sm text-gray-700">{t("Full Name")} *</span>
                <Input
                  type="text"
                  placeholder={t("userName")!}
                  className="mt-2 rounded border border-gray-300 p-2"
                  value={name}
                  onChange={nameChange}
                  onBlur={nameBlur}
                />
                {nameHasError && <p className="mt-1 text-red-600">{t("validName")}</p>}
              </div>

              <div className="mb-4 w-full">
                <span className="block text-sm text-gray-700">{t("Phone number")} *</span>
                <EgyptPhoneInput
                  className="w-full rounded border border-gray-300 p-2"
                  value={phone}
                  onChange={handlePhoneChange}
                  onBlur={handlePhoneBlur}
                />
                {phoneHasError && <p className="mt-1 text-red-600">{t("validPhone")}</p>}
              </div>

              <div className="mb-4 w-full">
                <span className="block text-sm text-gray-700">{t("Password")} *</span>
                <Input
                  type="password"
                  placeholder={t("password") || ""}
                  className="mt-2 rounded border border-gray-300 p-2"
                  value={password}
                  onChange={passwordChange}
                  onBlur={passwordBlur}
                />
                {passwordHasError && <p className="mt-1 text-red-600">{t("validPassword")}</p>}
              </div>

              <div className="mb-6 w-full">
                <span className="block text-sm text-gray-700">{t("Confirm Password")} *</span>
                <Input
                  type="password"
                  placeholder={t("confirmPassword") || ""}
                  className="mt-2 rounded border border-gray-300 p-2"
                  value={confirmPassword}
                  onChange={confirmPasswordChange}
                  onBlur={confirmPasswordBlur}
                />
                {confirmPasswordHasError && <p className="mt-1 text-red-600">{t("passwordMismatch")}</p>}
              </div>

			  <ButtonPrimary
  type="button" // Keep type as "button" to handle click event manually
  onClick={()=>handleButtonClick()} // Assign the onClick event
  className="w-full rounded-lg bg-blue-500 py-2 text-white"
  disabled={!formIsValid}
>
  {t("Sign Up")}
</ButtonPrimary>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
