import { useState, useEffect } from 'react';
import AddressPreview from './AddressPreview';
import AddressEdit from './AddressEdit';
import AddressAdd from './AddressAdd'; // Import AddressAdd
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export interface AddressData {
  id: number;
  city: number | null;
  name: string;
  phone: string | null;
  notes: string | null;
  whatsapp_share_link?: string | null;
  map_location: {
    lat: number;
    lng: number;
    address_name: string;
  };
}

export interface City {
  id: number;
  name: string;
}

const ParentComponent = () => {
  const { t } = useTranslation();
  const [selectedAddress, setSelectedAddress] = useState<AddressData | null>(null);
  const [addresses, setAddresses] = useState<AddressData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchAddresses = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://app.telefreik.com/api/transports/profile/address-book', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch addresses');
      
      const data = await response.json();
      const receivedAddresses = Array.isArray(data)
        ? data
        : data.data || data.addresses || data.items || [];

      setAddresses(receivedAddresses);
      setError(null);
    } catch (err) {
      setError(t('error.fetchAddresses'));
      toast.error(t('error.fetchAddresses'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const handleSave = () => {
    fetchAddresses(); // Refresh the list after save
    setSelectedAddress(null);
  };

  const handleDelete = (deletedId: number) => {
    setAddresses(prev => prev.filter(a => a.id !== deletedId));
  };

  if (loading) {
    return <div className="text-center p-4">{t('loading')}...</div>;
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="space-y-4 p-4 max-w-3xl mx-auto sm:px-6 lg:px-8">
      {addresses.length === 0 && !loading && (
        <div className="text-center text-gray-500">{t('address.noAddresses')}</div>
      )}

      {addresses.map(address => (
        selectedAddress?.id === address.id ? (
          <AddressEdit
            key={address.id}
            address={address}
            onSave={handleSave}
            onCancel={() => setSelectedAddress(null)}
          />
        ) : (
          <AddressPreview
            key={address.id}
            address={address}
            onEdit={() => setSelectedAddress(address)}
            onDelete={handleDelete}
          />
        )
      ))}

      {/* Add new address button */}
      <button
        onClick={() => setSelectedAddress({
          id: 0,
          city: 1, // Default city: Cairo
          name: '',
          phone: '',
          notes: null,
          map_location: {
            lat: 30.0444, // Cairo's latitude
            lng: 31.2357, // Cairo's longitude
            address_name: 'Cairo, Egypt',
          }
        })}
        className="w-full p-4 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 hover:bg-blue-50 transition-colors sm:w-auto sm:px-6"
      >
        {t('address.addNew')}
      </button>

      {/* Render empty form when adding new address */}
      {selectedAddress?.id === 0 && (
        <AddressAdd
          onSave={handleSave}
          onCancel={() => setSelectedAddress(null)}
        />
      )}
    </div>
  );
};

export default ParentComponent;
