import React, { FC, useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

type MoDateBickerProps = {
  date: Moment;
  setDate: (date: Moment) => void;
  defaultDateValue?: Moment | null;
  classNameContainer?: string;
  dateFormat?: string;
  minDate?: Moment;
  maxDate?: Moment;
};

export const MoDateBicker: FC<MoDateBickerProps> = ({
  date,
  setDate,
  defaultDateValue = null,
  classNameContainer = '',
  dateFormat = 'ddd, MMM D',
  minDate,
  maxDate,
}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [showCalendar, setShowCalendar] = useState(false);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  moment.locale(currentLang);
  const [currentMonth, setCurrentMonth] = useState<Moment>(moment(date));
  const [hoverDate, setHoverDate] = useState<Moment | null>(null);

  useEffect(() => {
    setCurrentMonth(moment(date));
  }, [date]);

  const startOfMonth = moment(currentMonth).startOf('month');
  const endOfMonth = moment(currentMonth).endOf('month');
  const startDate = moment(startOfMonth).startOf('week');
  const endDate = moment(endOfMonth).endOf('week');

  const day = startDate.clone().subtract(1, 'day');
  const calendarDays: Moment[] = [];

  while (day.isBefore(endDate, 'day')) {
    calendarDays.push(day.add(1, 'day').clone());
  }

  const handleDayClick = (day: Moment) => {
    setDate(day);
    setShowCalendar(false);
  };

  const handleDayMouseEnter = (day: Moment) => {
    if (defaultDateValue && day.isBefore(defaultDateValue, 'day')) {
      return;
    }
    setHoverDate(day);
  };

  const isInRange = (day: Moment) => {
    if (!hoverDate || !defaultDateValue) return false;
    return (
      day.isAfter(defaultDateValue, 'day') && day.isBefore(hoverDate, 'day')
    );
  };

  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.clone().add(1, 'month'));
  };

  const isDisabled = (day: Moment) => {
    if (defaultDateValue) {
      return day.isBefore(defaultDateValue, 'day');
    }
    return false;
  };

  const isDefaultDate = (day: Moment) => {
    return defaultDateValue ? day.isSame(defaultDateValue, 'day') : false;
  };

  const resetDate = () => {
    setDate(moment());
    setShowCalendar(false);
  };

  return (
    <div className={`m-1 w-full cursor-pointer ${classNameContainer}`} onClick={toggleCalendar}>
      <div className="flex items-center justify-between rounded gap-4 max-sm:rounded-full border border-gray-300 bg-white px-4 py-2">
        <div className="flex-grow text-left text-gray-800">
          <span className="block text-xs text-gray-600 mb-1">
            {date.format('LL')}
          </span>
          <span className="text-sm   max-sm:hidden">{date.format(dateFormat)}</span>
        </div>
        <span role="img" aria-label="calendar">📅</span>
      </div>
      {showCalendar && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="absolute top-15 right-0 w-fit max-sm:w-full h-fit z-50 bg-white border border-gray-300 max-sm:rounded-lg p-4">
          <div className="inline-block">
            <div className="mb-4 flex items-center justify-between">
              <button onClick={(e) => { e.stopPropagation(); handlePrevMonth(); }} className="text-blue-500 text-xl focus:outline-none">&lt;</button>
              <div>{currentMonth.format('MMMM YYYY')}</div>
              <button onClick={(e) => { e.stopPropagation(); handleNextMonth(); }} className="text-blue-500 text-xl focus:outline-none">&gt;</button>
            </div>
            <div className="grid grid-cols-7 gap-1">
              {moment.weekdaysShort(true).map((d) => (
                <div key={d} className="font-bold text-center text-sm">{d}</div>
              ))}
              {calendarDays.map((dayItem) => {
                const disabled = isDisabled(dayItem);
                const isSelected = dayItem.isSame(date, 'day');
                const inRange = isInRange(dayItem);
                const defaultDate = isDefaultDate(dayItem);
                let bgColor = defaultDate ? 'bg-green-500' : isSelected ? 'bg-blue-500' : inRange ? 'bg-blue-200' : 'bg-transparent';
                let textColor = defaultDate ? 'text-white' : isSelected ? 'text-white' : disabled ? 'text-gray-400 cursor-not-allowed' : 'text-black';

                return (
                  <div key={dayItem.format('YYYY-MM-DD')} className={`h-10 w-10 flex items-center justify-center max-sm:rounded ${bgColor} ${textColor} ${disabled ? 'pointer-events-none' : 'cursor-pointer'} hover:bg-blue-100`} onClick={(e) => { e.stopPropagation(); if (!disabled) handleDayClick(dayItem); }} onMouseEnter={() => handleDayMouseEnter(dayItem)}>
                    {dayItem.date()}
                  </div>
                );
              })}
            </div>
            <button onClick={(e) => { e.stopPropagation(); resetDate(); }} className="mt-4 px-4 py-2 bg-gray-600 text-white rounded focus:outline-none">{t('Reset to Today')}</button>
          </div>
        </motion.div>
      )}
    </div>
  );
};
