import React, { useState } from "react";
import useForm from "hooks/useForm";
import { updateProfile } from "api";
import { t } from "i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { showApiErrorMessages } from "utils";

interface EgyptPhoneInputProps {
  value: string;
  onChange: (value: string) => void;
  error?: string;
}

const EgyptPhoneInput = ({ value, onChange, error }: EgyptPhoneInputProps) => {
  const [inputValue, setInputValue] = useState(value.replace(/^(\+20|0020)/, ''));

  const validateEgyptNumber = (num: string) => /^(01[0-2,5,6,9]|02|03|0[4-9])\d{7,8}$/.test(num);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\D/g, "");
    setInputValue(rawValue);
    onChange(rawValue);
  };

  return (
    <div className="w-full space-y-2">
      <label className="block text-sm font-medium text-gray-700">{t("Phone number")} *</label>
      <div className={`flex items-center border rounded-lg bg-gray-100 ${error ? 'border-red-500' : 'border-gray-300 focus-within:border-blue-500'}`}>
        <div className="flex items-center px-3 bg-gray-100 h-full">
          <span className="mr-2 text-xl text-gray-600">🇪🇬</span>
        </div>
        <input
          type="tel"
          value={inputValue}
          onChange={handleChange}
          placeholder="e.g. 1012345678"
          className="w-full px-4 py-3 bg-transparent border-0 focus:outline-none"
          maxLength={11}
        />
      </div>
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
};

const EditProfile = () => {
  const navigate = useNavigate();
  const [values, setValues] = useForm({
    name: localStorage.getItem("name") ?? "",
    email: localStorage.getItem("email") ?? "",
    mobile: localStorage.getItem("phone")?.replace(/^(\+20|0020)/, '') ?? "",
    country_code: "20",
  });
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  const validateForm = () => {
    setPhoneError("");

    if (!/^(01[0-2,5,6,9]|02|03|0[4-9])\d{7,8}$/.test(values.mobile)) {
      setPhoneError(t("Invalid Egyptian phone number") || "Invalid phone number format");
      return false;
    }
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ target: { name: e.target.name, value: e.target.value } });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const fullNumber = `${values.mobile}`;

      await updateProfile({ ...values, mobile: fullNumber });

      localStorage.setItem("name", values.name);
      localStorage.setItem("email", values.email);
      localStorage.setItem("phone", fullNumber);

      toast.success(t("Profile updated successfully!") || "Success!");
      setValues({ name: "", email: "", mobile: "", country_code: "20" });
    } catch (err: any) {
      if (err?.response?.data?.errors) {
        showApiErrorMessages(err.response.data.errors);
      }
      if (err.response?.status === 401) navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto max-w-2xl p-6 bg-white rounded-2xl shadow-lg mt-8">
      <h1 className="text-2xl font-semibold text-gray-800 mb-8">{t("Edit profile")}</h1>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">{t("Full Name")} *</label>
          <input
            type="text"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">{t("Email address")} *</label>
          <input
            type="email"
            name="email"
            value={values.email}
            onChange={handleInputChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <EgyptPhoneInput
          value={values.mobile}
          onChange={(value) => setValues({ target: { name: "mobile", value } })}
          error={phoneError}
        />

        <button
          type="submit"
          disabled={loading}
          className="w-full py-3 px-6 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition-colors disabled:opacity-50"
        >
          {loading ? t("Processing...") : t("Save Changes")}
        </button>
      </form>
    </div>
  );
};

export default EditProfile;
