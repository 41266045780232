import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import convertNumToList from "utils/convertNumToList";

// Define the Seat interface
interface Seat {
  seat_no: null | number;
  class: string;
}

/**
 * Extracts data from a URL and returns it as a key-value object.
 * @param url - The URL string.
 * @returns {Record<string, string>} - The extracted data as a key-value object.
 */
function extractDataFromURL(url: string): Record<string, string> {
  const parts = url.split("/").filter(Boolean);
  const data: Record<string, string> = {};

  parts.forEach((part, index) => {
    const key = `key${index + 1}`;
    data[key] = part;
  });

  return data;
}

/**
 * TazcraBus component.
 * @param {Object} props - The component props.
 * @param {Function} props.setSelected - The callback function to set the selected seats.
 * @param {Seat[]} props.seats - The array of available seats.
 * @param {Seat[]} props.selected - The array of selected seats.
 * @param {string} props.salon - The salon data.
 * @param {number} props.cols - The number of columns in the salon.
 * @param {number} props.rows - The number of rows in the salon.
 * @returns {JSX.Element} - The TazcraBus component.
 */
export const TazcraBus = ({
  setSelected,
  seats,
  selected,
  salon,
  busDirection,
  cols,
  rows,
}: any): JSX.Element => {
  const { t } = useTranslation();
  const [selectedList, setSelectedList] = useState<Record<string, string>>({});
  const [company, setCompany] = useState<string>("");

  const seatList = convertNumToList(49);

  /**
   * Handles seat selection when a seat is clicked.
   * @param {React.MouseEvent<HTMLDivElement>} e - The click event.
   */
  const handleSeatClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;

    if (target.classList.contains("bg-seat")) {
      const seatId = target.id;

      if (seatId) {
        const seatKey = `seat_${seatId}`;
        const updatedSelectedList = { ...selectedList };

        if (updatedSelectedList[seatKey] === undefined) {
          // Add seat to selected list
          updatedSelectedList[seatKey] = seatId;
          target.classList.add("selected-seat");
        } else {
          // Remove seat from selected list
          delete updatedSelectedList[seatKey];
          target.classList.remove("selected-seat");
        }

        setSelectedList(updatedSelectedList);
        setSelected(updatedSelectedList);
      }
    } else {
      console.log("Cannot book. First condition is:", target.classList.contains("available"));
    }
  };

  /**
   * Updates the class of seat elements based on the seats array.
   */
  const updateSeatClasses = () => {
    seatList.forEach((item) => {
      const seatElement = document.getElementById(item.toString());

      if (seatElement) {
        const isSeatAvailable = seats.some((seat:any) => seat.seat_no?.toString() === item.toString());

        if (isSeatAvailable) {
          seatElement.classList.remove("selected-seat");
        } else {
          seatElement.classList.add("occupied");
        }
      }
    });
  };

  /**
   * Sets all seats as occupied.
   */
  const setAllSeatsOccupied = () => {
    seatList.forEach((item) => {
      const seatElement = document.getElementById(item.toString());

      if (seatElement) {
        seatElement.classList.add("selected-bg");
      }
    });
  };

  // Extract the company name from the URL
  useEffect(() => {
    const urlData = extractDataFromURL(window.location.href);
    setCompany(urlData.key11);
  }, []);

  // Update seat classes when seats change
  useEffect(() => {
    if (seats.length > 0) {
      updateSeatClasses();
    } else {
      setAllSeatsOccupied();
    }
  }, [seats]);

  /**
   * Generates the salon layout for the TazcraBus component.
   * @returns {JSX.Element[]} - The array of seat elements.
   */
  const generateSalonLayout = (): JSX.Element[] => {
    const salonLayout: JSX.Element[] = [];

    for (let row = 0; row < rows; row++) {
      const rowSeats: JSX.Element[] = [];

      for (let col = 0; col < cols; col++) {
        const seatIndex = row * cols + col;
        const seat = seats[seatIndex];
        const seatNo = seat?.seat_no ?? null;

        // Define the class for the seat element
        const seatClass = getSeatClass(seat?.class);

        rowSeats.push(
          <div
            key={seatIndex}
            className={`seat ${seatClass} flex items-end justify-center text-[14px] font-[600] text-white hover:cursor-pointer`}
            id={seatNo?.toString()}
            onClick={handleSeatClick}
            title={seatNo?.toString()}
          >
            {seatNo}
          </div>
        );
      }

      salonLayout.push(
        <div key={row} className="flex flex-row items-center justify-center rtl:flex-row-reverse">
          {rowSeats}
        </div>
      );
    }

    return salonLayout;
  };

  /**
   * Returns the appropriate CSS class for a seat based on its type.
   * @param {string} seatType - The type of the seat.
   * @returns {string} - The CSS class for the seat.
   */
  const getSeatClass = (seatType: string | undefined): string => {
    switch (seatType) {
      case "available":
        return "bg-seat";
      case "booked":
        return "bg-seat selected-bg";
      case "driver":
        return "driver-bg";
      case "wc":
        return "toilet-bg";
      case "space":
        return "space-bg";
      case "door":
        return "door-bg";
      default:
        return "";
    }
  };

  return (
    <div className="salon-container mt-5">
      <div className="salon-title movie-container">
        <ul className="showcase">
          <li>
            <div className="bg-seat"></div>
            <small>{t("availableSeat")}</small>
          </li>
          <li>
            <div className="bg-seat selected-seat"></div>
            <small>{t("ownSelect")}</small>
          </li>
          <li>
            <div className="bg-seat selected-bg"></div>
            <small>{t("notAvailable")}</small>
          </li>
        </ul>
      </div>
      <div className="salon-layout">{generateSalonLayout()}</div>
    </div>
  );
};