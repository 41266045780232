import React, { useEffect, useState } from 'react';
import { t } from 'i18next';

interface EgyptPhoneInputProps {
  value: string;
  onChange: (value: string) => void;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  onBlur?: () => void;
}

const EgyptPhoneInput: React.FC<EgyptPhoneInputProps> = ({
  value,
  onChange,
  error,
  required = false,
  disabled = false,
  className = '',
  onBlur,
}) => {
  const [displayValue, setDisplayValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [internalError, setInternalError] = useState('');

  const patterns = {
    mobile: /^1[02569]\d{8}$/,
    cairoLandline: /^2\d{7}$/,
    alexLandline: /^3\d{6}$/,
    otherLandline: /^[4-9]\d{6,7}$/,
  };

  useEffect(() => {
    const initialValue = value.startsWith('+20') ? value.slice(3) : value;
    setDisplayValue(formatNumber(initialValue));
  }, [value]);

  const validateNumber = (nationalNumber: string): { isValid: boolean; message: string } => {
    let cleaned = nationalNumber.replace(/\D/g, '');
  
    // Add leading '0' if missing for valid numbers
    if (!cleaned.startsWith('0') && /^[1-9]/.test(cleaned)) {
      cleaned = '0' + cleaned;
    }
  
    if (cleaned.length === 0) {
      return { isValid: false, message: t('Phone number is required') || 'Phone number is required' };
    }
  
    // Mobile numbers (11 digits)
    if (patterns.mobile.test(cleaned) && cleaned.length === 11) {
      return { isValid: true, message: '' };
    }
  
    // Cairo landlines (8 digits)
    if (patterns.cairoLandline.test(cleaned) && cleaned.length === 8) {
      return { isValid: true, message: '' };
    }
  
    // Alexandria landlines (7 digits)
    if (patterns.alexLandline.test(cleaned) && cleaned.length === 7) {
      return { isValid: true, message: '' };
    }
  
    // Other landlines (7-8 digits)
    if (patterns.otherLandline.test(cleaned) && (cleaned.length === 7 || cleaned.length === 8)) {
      return { isValid: true, message: '' };
    }
  
    // Length-based error messages
    if (cleaned.length < 7) {
      return { isValid: false, message: t('Number too short') || 'Number is too short' };
    }
    if (cleaned.length > 11) {
      return { isValid: false, message: t('Number too long') || 'Number is too long' };
    }
  
    return { isValid: true, message: t('good jop') || 'god jop' };
  };
  

  


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    let cleaned = input.replace(/\D/g, '');
  
    // Auto-add leading '0' if missing
    if (!cleaned.startsWith('0') && /^[1-9]/.test(cleaned)) {
      cleaned = '0' + cleaned;
    }
  
    const formatted = formatNumber(cleaned);
    setDisplayValue(formatted);
    const validation = validateNumber(cleaned);
  
    if (validation.isValid) {
      onChange(`${cleaned.replace(/^0/, '')}`);
      setInternalError('');
    } else {
      onChange('');
      setInternalError(validation.message);
    }
  };
  
  const formatNumber = (input: string) => {
    const cleaned = input.replace(/\D/g, '').replace(/^0+/, '');
    if (cleaned.startsWith('1') && cleaned.length === 10) return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2 $3');
    if (cleaned.startsWith('2') && cleaned.length === 8) return cleaned.replace(/(\d{1})(\d{3})(\d{4})/, '($1) $2 $3');
    if (cleaned.startsWith('3') && cleaned.length === 7) return cleaned.replace(/(\d{1})(\d{3})(\d{3})/, '($1) $2 $3');
    if (/^[4-9]/.test(cleaned)) return cleaned.replace(/(\d{1})(\d{3})(\d{3,4})/, '($1) $2 $3');
    return cleaned;
  };

  return (
    <div className={`space-y-2 ${className}`}>
      <label className="block text-sm font-medium text-gray-700">
        {t('Phone number')} {required && '*'}
      </label>
      <div className={`flex items-center border ${error || internalError ? 'border-red-500' : isFocused ? 'border-blue-500 ring-2 ring-blue-200' : 'border-gray-300'} rounded-lg transition-all duration-200 ${disabled ? 'bg-gray-100' : 'bg-white'}`}>
        <div className="flex items-center px-3 border-r border-gray-200 bg-gray-50 h-full">
          <span className="mr-2 text-xl">🇪🇬</span>
          <span className="text-gray-600">+20</span>
        </div>
        <input
          type="tel"
          value={displayValue}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => { setIsFocused(false); onBlur?.(); if (!displayValue && required) setInternalError('Phone number is required'); }}
          placeholder={'e.g. 101 234 5678'}
          className={`w-full px-4 py-3 bg-transparent focus:outline-none placeholder-gray-400 ${disabled ? 'cursor-not-allowed' : ''}`}
          maxLength={14}
          disabled={disabled}
          aria-invalid={!!(error || internalError)}
          aria-required={required}
        />
      </div>
      {(error || internalError) && <p className="text-red-500 text-sm mt-1">{error || internalError}</p>}
    </div>
  );
};

export default EgyptPhoneInput;
