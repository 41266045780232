import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import bus from "images/bluebus.jpeg";
import tv from "images/outline.png";
import conditioning from "images/air-conditioner 1.png";
import moment from "moment";
import { toast } from "react-toastify";
import { TravelData } from "utils/types";
import miniBus from "images/bus1.png";

export interface FlightCardProps {
	refactoredData?: TravelData[];
}

const FlightCard: React.FC<FlightCardProps> = ({ refactoredData = [] }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	// Remove duplicate trips based on trip_url
	const removeDuplicates = (travelData: TravelData[]) => {
		const uniqueData: TravelData[] = [];
		const keySet = new Set<string>();

		for (const item of travelData) {
			const key = item.trip_url;
			if (!keySet.has(key)) {
				uniqueData.push(item);
				keySet.add(key);
			}
		}

		return uniqueData;
	};

	// Calculate duration between two dates
	const calculateDuration = (startDate: string, endDate: string): string => {
		const formats = ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"];
		let duration: moment.Duration | null = null;

		for (const format of formats) {
			const start = moment(startDate, format);
			const end = moment(endDate, format);
			if (start.isValid() && end.isValid()) {
				duration = moment.duration(end.diff(start));
				break;
			}
		}

		if (!duration) {
			throw new Error("Invalid date format for start_date or end_date");
		}

		const hours = Math.floor(duration.asHours());
		const minutes = Math.floor(duration.asMinutes() % 60);

		return `${t("H")} ${hours} ${t("M")} ${minutes}`;
	};

	// Get bus image based on gateway_id
	const getBusImage = (item: TravelData) => {
		if (item.company_data?.bus_image) return item.company_data.bus_image;
		if (item.gateway_id.includes("WEBUS")) return miniBus;
		if (item.gateway_id.includes("BlueBus")) return bus;
		return item.company_logo;
	};

	// Render bus cards
	const renderBusCards = (data: TravelData[]) => {
		return data.map(item => {
			const busImage = getBusImage(item);
			const duration = calculateDuration(item.travel_at, item.arrival_at);

			return (
				<div key={item.id} className="mb-4 rounded-lg bg-white p-4 shadow-md">
					{/* Header Section */}
					<div className="flex items-center justify-between py-4 border-b border-[#e8ecf2] ">
						<img
							src={item.company_data?.avatar}
							alt="Company Logo"
							className="w-[83.33px] h-10 object-cover"
						/>
						<div className="flex items-center gap-2">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12 6V12H16.5M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
									stroke="#69696A"
									strokeWidth="2"
									strokeLinejoin="round"
								/>
							</svg>
							<span className="text-gray-600">{duration}</span>
						</div>
					</div>

					{/* Body Section */}
					<div className="flex justify-between border-b py-4 max-sm:flex-col max-sm:gap-6 ">
						<div className="flex flex-row gap-4   ">
							<div className="h-100 w-fit items-center pb-5 pt-1 align-middle opacity-50">
								<div className="mx-auto h-2 w-2 rounded-full  bg-black"></div>
								<div className="mx-auto h-full w-[2px] bg-black"></div>
								<div className="mx-auto h-2 w-2 rounded-full  bg-black"></div>
							</div>
							<div className="h-100 w-fit items-center align-middle">
								<div className=" flex h-fit flex-col justify-between gap-2 ">
									<h4 className="text-lg font-semibold">
										{item.city_from_name} ({item.travel_from})
									</h4>
									<p className="text-gray-600">{item.travel_at}</p>
								</div>
								<div className="flex h-fit flex-col justify-between gap-2 ">
									<h4 className="text-lg font-semibold">
										{item.city_to_name} ({item.travel_to})
									</h4>
									<p className="text-gray-600">{item.arrival_at}</p>
								</div>
							</div>
						</div>
						<img
							src={busImage}
							alt="Bus"
							className="w-[106.18px] h-20 object-contain"
						/>
					</div>

					{/* Footer Section */}
					<div className="flex flex-row    justify-between pt-4 max-sm:flex-col max-sm:items-center">
						<div className="flex items-center  justify-between  px-4  py-4 max-sm:w-full   max-sm:flex-row max-sm:items-stretch ">
							<div className="flex gap-2 text-center ">
								<img src={tv} alt="TV" className="h-6 w-6" />
								<img src={conditioning} alt="AC" className="h-6 w-6" />
								{item.classes && (
									<div
										className={`rounded-full px-3 py-1 text-sm ${
											item.classes === "Prime_Mix"
												? "bg-[#C2FBFF] text-[#00939C]"
												: item.classes === "First10"
												? "bg-[#FFC2E3] text-[#A50059]"
												: item.classes === "first_class"
												? "bg-[#FFC2C2] text-[#AE0000]"
												: item.classes === "Business 40"
												? "bg-[#FFE3C2] text-[#AD5E00]"
												: item.classes === "Economy"
												? "bg-[#E4FFC2] text-[#4C8900]"
												: item.classes === "Comfort"
												? "bg-[#C2DEFF] text-[#004EAA]"
												: "bg-[#F9B35F] text-[#3A342D]"
										}`}
									>
										{item.classes}
									</div>
								)}
							</div>
							<div
								className=" px-6 text-center "
								style={{ borderRadius: "24px", textAlign: "center" }}
							>
								{item?.available_seats !== 0 && (
									<div className="max-sm:text-[1rem] sm:text-[1rem]">
										{item?.available_seats} {t("seats free")}{" "}
									</div>
								)}
							</div>
						</div>
						<div className="self-stretch justify-start items-center gap-4 inline-flex">

							<div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex ">
								<div className="text-[#1e1e1e] text-base font-medium font-['Cairo']">{item.prices_start_with} {t("LE")}</div>
					
								<div className="text-[#68696a] text-xs font-normal font-['Cairo'] leading-[18px]">
									{t("Price per person")}
								</div>
							</div>
							<div className="w-[110px] h-12 justify-start items-start flex">

							<div className="grow shrink basis-0 self-stretch p-4 bg-[#0074c3] rounded-[9px] shadow-[0px_4px_4px_0px_rgba(217,217,217,0.25)] justify-center items-center gap-2 flex">

							<button
								className="text-white text-xl font-medium font-['Cairo'] leading-[30px]"
								onClick={() => {
									navigate(item.trip_url);
									localStorage.setItem("current_company_name", item.gateway_id);
								}}
							>
								{t("select")}
							</button>
							</div>
							</div>
						</div>
					</div>
				</div>
			);
		});
	};

	const uniqueData = removeDuplicates(refactoredData);
	return (
		<div className="max-sm:h-[70vh] h-full max-h-[2400px] overflow-auto max-sm:scale-95">{renderBusCards(uniqueData)}</div>
	);
};

export default FlightCard;
