import React, { FC } from 'react';
import classes from "./ProfileButtom.module.css";

interface ProfileButtomProps extends React.ComponentPropsWithoutRef<'button'> {
    title: string;
    mt: boolean;
}

const ProfileButtom: FC<ProfileButtomProps> = ({
    title,
    mt,
    type = 'button', // Default to 'button' type
    ...props
}) => {
    const buttonClass = mt ? `${classes.EditBtn} mt-96` : classes.Edit;

    return (
        <button
            type={type}
            className={buttonClass}
            {...props}
        >
            {title}
        </button>
    );
};

export default ProfileButtom;