import React, { FC, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInput from "hooks/useInput";
import { useAddLogin } from "hooks/DataSend/useLogin";
import { AppContext } from "components/context/AppContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import i18n from "i18n";
import { Helmet } from "react-helmet-async";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const { mutate, isLoading, error } = useAddLogin();
  const { token } = useContext(AppContext);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    value: password,
    valueChangeHandler: passwordChange,
  } = useInput((value: string) => true); // No validation for password

  const {
    value: phone,
    isValid: phoneIsValid,
    hasError: phoneHasError,
    inputBlurHandler: phoneBlur,
    valueChangeHandler: phoneChange,
  } = useInput((value: string) => /^\d{10,12}$/.test(value)); // Validates phone format

  const formSubmitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    if (!phoneIsValid) {
      console.warn("Validation failed: Invalid phone number");
      return;
    }

    const officialPhone = phone.startsWith("0") ? phone.substring(1) : phone;
    const data = new FormData();
    data.append("mobile", officialPhone);
    data.append("phonecode", "20");
    data.append("password", password);

    console.log("Sending login request:", Object.fromEntries(data));

    mutate(data, {
      onSuccess: () => {
        localStorage.setItem("phone", phone);
        console.log("Login successful!");
      },
      onError: (err) => {
        console.error("Login failed:", err);
      },
    });
  };

  const currentLanguage: string = i18n.language;

  return (
    <div className={`nc-PageLogin ${className} relative mb-20 h-[80vh] bg-gradient-to-r from-[#2E74BD] to-[#2B356E] max-sm:mb-[35%]`}>
      <Helmet>
        <title>
          {currentLanguage === "ar"
            ? "احجز أفضل عروض الطیران والمواصلات في مصر - سفریة"
            : "Book flight and transportation deals in Egypt - Safaria"}
        </title>
      </Helmet>

      <div className="container absolute top-20 left-2 right-2 w-[80vw] rounded-[16px] bg-white shadow-lg transition-[0.5s] lg:mb-32">
        <div className="mt-[42px] flex justify-center">
          <div className="flex h-[50px] w-[170px] items-center justify-center rounded-[24px] bg-[#0074C3] text-white">
            {t("login")}
          </div>
          <div className="flex h-[50px] w-[170px] items-center justify-center rounded-[24px]">
            {!token && (
              <Link to="/signup" className="block text-center text-neutral-700 dark:text-neutral-300">
                {t("Sign up")}
              </Link>
            )}
          </div>
        </div>

        <div className="mx-auto max-w-md space-y-14">
          <form className="grid grid-cols-1 gap-4" onSubmit={formSubmitHandler}>
            <label className="block">
              <span className="text-[#69696A] dark:text-neutral-200">{t("phoneNumber")}*</span>
              <input
                type="tel"
                pattern="[0-9]{10,12}"
                placeholder="0105478...."
                className="h-14 mt-2 w-full rounded-md border border-gray-300 p-3 text-[#69696A]"
                value={phone}
                onChange={phoneChange}
                onBlur={phoneBlur}
                autoFocus
              />
              {phoneHasError && <p className="mt-1 text-red-900">{t("Enter a valid phone number")}</p>}
            </label>

            <label className="block">
              <span className="text-[#69696A] dark:text-neutral-200">{t("password")}</span>
              <div className="relative">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  className="h-14 w-full rounded-md border border-gray-300 p-3 text-[#69696A]"
                  value={password}
                  onChange={passwordChange}
                  placeholder="Enter password"
                />
                <button
                  type="button"
                  onClick={() => setIsPasswordVisible((prev) => !prev)}
                  className="absolute top-1/2 right-3 transform -translate-y-1/2"
                >
                  {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </label>

            <button type="submit" className="h-12 w-full rounded-[20px] bg-blue-600 text-white hover:bg-blue-700 transition-all">
              {isLoading ? t("loading") : t("continue")}
            </button>

            {error && <p className="mt-2 text-red-600">{t("loginError")}</p>}

            <label className="flex justify-center">
              <Link to="/forgot-pass" className="text-blue-700 dark:text-blue-700">
                {t("forgotPassword")}?
              </Link>
            </label>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
