import React, {  useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import formatDate from "utils/formatDateMo";
import formatTime from "utils/from24HourFormatTo12";
import { TravelData } from "../../../utils/types";
import { useTranslation } from "react-i18next";
import sortByTravelAt from "utils/sortTravelDataByTravelAt";
import sortByPrice from "utils/sortedTravelDataByPrices";
import convertStringToArray from "utils/convertStringToArr";
import filtered_data_to_set_data from "utils/filterTravleDataInManyAttr";

interface SliderFiltersInRangeProps {
	RefactoredData: TravelData[]; // Add the appropriate type for RefactoredData
	displayedData: TravelData[]; // Add the appropriate type for RefactoredData
	times: string[];
	type: string;
	setData: Function;
	companyNamesList: string[];
	classes_trip: string[];
	slider_stations: string[];
	arrival_stations: string[];
}

const SliderFiltersInRange: React.FC<SliderFiltersInRangeProps> = ({
	RefactoredData,
	setData,
	displayedData,
	times,
	type,
	companyNamesList,
	classes_trip,
	slider_stations,
	arrival_stations,
}) => {

	const [start_Arrival_date, setStartArrivalDate] = useState<string>(times[0]);
	const [start_Arrival_time, setStartArrivalTime] = useState<string>(times[0]);
	
	const [end_Arrival_time, setEndArrivalTime] = useState<string>(
		times[times.length - 1],
	);
	
	const { t } = useTranslation();
	useEffect(()=>{
		type === 'price' && localStorage.setItem(
			"listPrices",
			JSON.stringify(times),
		);
		type === 'traveling' && localStorage.setItem(
			"listDepartureTimes",
			JSON.stringify(times),
		);
		type === 'arrival' && localStorage.setItem(
			"listArrivalTimes",
			JSON.stringify(times),
		);
	},[])
	const handleSliderChangeInArrival = (e: any) => {
		// console.log(listArrivalTimes, listDepartureTimes, 4444);
		type === 'price' && localStorage.setItem(
			"listPrices",
			JSON.stringify(times.slice(e[0], e[1])),
		);
		type === 'traveling' && localStorage.setItem(
			"listDepartureTimes",
			JSON.stringify(times.slice(e[0], e[1])),
		);
		type === 'arrival' && localStorage.setItem(
			"listArrivalTimes",
			JSON.stringify(times.slice(e[0], e[1])),
		);
		setStartArrivalDate(times.slice(e[0], e[1])[0]);
		setStartArrivalTime(times.slice(e[0], e[1])[0]);
		setEndArrivalTime(times.slice(e[0], e[1])[times.slice(e[0], e[1]).length -1]);
	
		type === 'price' && setEndArrivalTime(
			times.slice(e[0], e[1])[times.slice(e[0], e[1]).length - 1],
		);
		let storedArrivalTimes  = convertStringToArray(`${localStorage.getItem("listArrivalTimes")}`);
		let storedDepartureTimes = convertStringToArray(`${localStorage.getItem("listDepartureTimes")}`);
		let storedPrice = convertStringToArray(`${localStorage.getItem("listPrices")}`);
	
		setData(filtered_data_to_set_data(
			RefactoredData,
			storedArrivalTimes,
			storedDepartureTimes,
			storedPrice,
			companyNamesList,
			classes_trip,
			slider_stations,
			arrival_stations
		),);
		
	};
	return (
		<div className="m-auto w-full px-4 max-sm:w-[200px] max-sm:pb-8 rtl:flex-row z-50">
			<h6 className=" flex justify-between text-sm text-slate-500  ">
				{type === "arrival" ? t("arrival to") :type === "traveling"? t("Depart from") : t("Price")}{" "}
				{type === "arrival"
					? RefactoredData[0].city_to_name
					: type === "traveling"? RefactoredData[0].city_from_name :" "}{" "}
				<span className="text-xm h-[18px] w-[60px] rounded-lg bg-blue-300 text-center align-middle font-bold text-stone-900">
					{type === "price"? "" : formatDate(start_Arrival_date)}
				</span>
			</h6>
			<div className="my-3 flex justify-between rtl:flex-row-reverse">
				<span className="text-xs">
					{ type === "price" ?start_Arrival_time:formatTime(start_Arrival_time)}{" "}
					<small> {type === "price" ?" " :`(${formatDate(start_Arrival_time)})`}</small>{" "}
				</span>
				<span className="text-xs"> { type === "price" ?end_Arrival_time:formatTime(end_Arrival_time)}</span>
			</div>
			<Slider
				range
				pushable={true}
				className="text-red-400 "
				min={0}
				max={times.length }
				defaultValue={[0, times.length ]}
				allowCross={false}
				step={1}
				onChange={(e: any) => {
					handleSliderChangeInArrival(e);
				}}
			/>
		</div>
	);
};

export default SliderFiltersInRange;
