import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "@material-tailwind/react";
import ProfileButtom from "components/ProfileButtom/ProfileButtom";
import { useChangePass } from "hooks/DataSend/useChangePass";
import { toast } from "react-toastify";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";

const PasswordCard = () => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useChangePass();
  const phone = localStorage.getItem("phone");
  
  const [passwords, setPasswords] = useState({
    current: "",
    new: "",
    confirm: ""
  });
  
  const [visibility, setVisibility] = useState({
    current: false,
    new: false,
    confirm: false
  });

  const [errors, setErrors] = useState({
    current: "",
    new: "",
    confirm: ""
  });

  useEffect(() => {
    if (!phone) navigate("/login");
  }, [phone, navigate]);

  const toggleVisibility = (field: keyof typeof visibility) => {
    setVisibility(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { current: "", new: "", confirm: "" };

    if (!passwords.current) {
      newErrors.current = "Current password is required";
      isValid = false;
    }

    if (!passwords.new) {
      newErrors.new = "New password is required";
      isValid = false;
    } else if (passwords.new.length < 8) {
      newErrors.new = "Password must be at least 8 characters";
      isValid = false;
    }

    if (passwords.new !== passwords.confirm) {
      newErrors.confirm = "Passwords do not match";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append("phonecode", "20");
    formData.append("mobile", phone || "");
    formData.append("current_password", passwords.current);
    formData.append("new_password", passwords.new);
    formData.append("new_password_confirmation", passwords.confirm);

    mutate(formData, {
      onSuccess: () => {
        toast.success("Password changed successfully");
        setPasswords({ current: "", new: "", confirm: "" });
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || "Password change failed");
      }
    });
  };

  return (
    <div className="w-full p-4 md:p-6 bg-white rounded-2xl shadow-[0px_4px_4px_0px_rgba(217,217,217,0.25)] mx-4 md:mx-auto my-8 md:my-0 md:translate-y-[10vh]">
      <h2 className="text-[#1E1E1E] text-lg md:text-xl font-normal mb-4 md:mb-6">
        Change Password
      </h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Current Password */}
        <div className="mb-4 md:mb-6 relative">
          <label className="text-[#68696A] text-sm md:text-base font-normal mb-1 md:mb-2 block">
            Current Password *
          </label>
          <div className="relative">
            <Input
              type={visibility.current ? "text" : "password"}
              placeholder="Enter current password"
              value={passwords.current}
              onChange={(e) => setPasswords(p => ({ ...p, current: e.target.value }))}
              error={!!errors.current}
              className="w-full h-[44px] md:h-[54px] rounded border border-[#DDE2EB] p-2 md:p-3 text-sm md:text-base text-[#68696A] focus:outline-none focus:border-[#0074C3] pr-10"
            />
            <button
              type="button"
              onClick={() => toggleVisibility('current')}
              className="absolute right-3 top-1/2 -translate-y-1/2"
              aria-label={visibility.current ? "Hide password" : "Show password"}
            >
              {visibility.current ? (
                <EyeSlashIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-500" />
              )}
            </button>
          </div>
          {errors.current && <span className="text-red-500 text-xs">{errors.current}</span>}
        </div>

        {/* New Password */}
        <div className="mb-4 md:mb-6 relative">
          <label className="text-[#68696A] text-sm md:text-base font-normal mb-1 md:mb-2 block">
            New Password *
          </label>
          <div className="relative">
            <Input
              type={visibility.new ? "text" : "password"}
              placeholder="Enter new password"
              value={passwords.new}
              onChange={(e) => setPasswords(p => ({ ...p, new: e.target.value }))}
              error={!!errors.new}
              className="w-full h-[44px] md:h-[54px] rounded border border-[#DDE2EB] p-2 md:p-3 text-sm md:text-base text-[#68696A] focus:outline-none focus:border-[#0074C3] pr-10"
            />
            <button
              type="button"
              onClick={() => toggleVisibility('new')}
              className="absolute right-3 top-1/2 -translate-y-1/2"
              aria-label={visibility.new ? "Hide password" : "Show password"}
            >
              {visibility.new ? (
                <EyeSlashIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-500" />
              )}
            </button>
          </div>
          {errors.new && <span className="text-red-500 text-xs">{errors.new}</span>}
        </div>

        {/* Confirm New Password */}
        <div className="mb-6 md:mb-8 relative">
          <label className="text-[#68696A] text-sm md:text-base font-normal mb-1 md:mb-2 block">
            Confirm New Password *
          </label>
          <div className="relative">
            <Input
              type={visibility.confirm ? "text" : "password"}
              placeholder="Confirm new password"
              value={passwords.confirm}
              onChange={(e) => setPasswords(p => ({ ...p, confirm: e.target.value }))}
              error={!!errors.confirm}
              className="w-full h-[44px] md:h-[54px] rounded border border-[#DDE2EB] p-2 md:p-3 text-sm md:text-base text-[#68696A] focus:outline-none focus:border-[#0074C3] pr-10"
            />
            <button
              type="button"
              onClick={() => toggleVisibility('confirm')}
              className="absolute right-3 top-1/2 -translate-y-1/2"
              aria-label={visibility.confirm ? "Hide password" : "Show password"}
            >
              {visibility.confirm ? (
                <EyeSlashIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-500" />
              )}
            </button>
          </div>
          {errors.confirm && <span className="text-red-500 text-xs">{errors.confirm}</span>}
        </div>

        {/* Submit Button */}
        <ProfileButtom
          type="submit"
          title={isLoading ? "Processing..." : "Done"}
          mt={false}
          disabled={isLoading}
          className="w-full p-3 md:p-4 bg-[#0074C3] rounded-[9px] shadow-[0px_4px_4px_0px_rgba(217,217,217,0.25)] text-sm md:text-base text-white font-medium hover:bg-[#005A9C] transition-colors disabled:opacity-75"
        />
      </form>
    </div>
  );
};

export default PasswordCard;