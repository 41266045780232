import React from "react";
import classes from "./ChangePassword.module.css";
import { BackButtom } from "components/BackButtom/BackButtom";
import ProfileCard from "components/ProfileCard/ProfileCard";
import PasswordCard from "components/PasswordCard/PasswordCard";
import Layout from "shared/FaceBook/Layout";
const ChangePassord = () => {
	return (
		<Layout>

<div className="h-[30vh] w-[100vw] bg-[#005792]"></div>
<div className="">

<div className="container   translate-y-[-30vh] max-sm:translate-y-[-60vw]">
					<BackButtom />
					<div className="two mt-24 mb-5 min-h-[80vh] gap-[20px] bg-transparent  ">
						<ProfileCard />
					<PasswordCard />
				</div>
			</div>
		</div>
		</Layout>
	);
};

export default ChangePassord;
