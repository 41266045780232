import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { AddressData } from './ParentComponent';

interface Location {
  id: number;
  name: string;
  name_ar: string;
  name_en: string;
}

interface AddressAddProps {
  onSave: () => void;
  onCancel: () => void;
}

const AddressAdd = ({ onSave, onCancel }: AddressAddProps) => {
  const { t, i18n } = useTranslation();
  const [locations, setLocations] = useState<Location[]>([]);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    notes: '',
    city: '',
    lat:  30.0072,
    lng: 31.2325,
  });

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch('https://app.telefreik.com/api/transports/locations/private');
        const data = await response.json();
        console.log(data);
        if (Array.isArray(data.data)) {
          setLocations(data.data);
        } else {
          setLocations([]); // Default to an empty array to prevent errors
        }
      } catch (error) {
        toast.error(t('error'));
      }
    };
    fetchLocations();
  }, [t]);

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setFormData(prev => ({
        ...prev,
        lat: e.latLng!.lat(),
        lng: e.latLng!.lng(),
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const url = 'https://app.telefreik.com/api/transports/profile/address-book';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...formData,
          city: parseInt(formData.city),
          map_location: {
            lat: formData.lat,
            lng: formData.lng,
            address_name: formData.name,
          },
        }),
      });

      if (response.ok) {
        toast.success(t('address.createdSuccess'));
        onSave();
      }
    } catch (error) {
      toast.error(t('error'));
    }
  };

  const getLocalizedCityName = (location: Location) => {
    return i18n.language === 'ar' ? location.name_ar : location.name_en;
  };

  return (
    <div className="bg-white py-4 px-2 rounded-xl flex flex-col justify-start items-start gap-5">
      <div className="flex flex-col justify-start items-start gap-3 w-full">
        <div className="flex justify-start items-start gap-5 w-full">
          {/* Name Field */}
          <div className="flex flex-col justify-start items-start gap-3 flex-1">
            <div className="text-[#68696a] text-base font-normal font-['Cairo'] leading-normal">
              {t('address.name')} *
            </div>
            <div className="w-full h-[54px] relative">
              <input
                className="w-full h-full px-4 py-2 rounded border border-[#dde2eb] focus:outline-none focus:border-[#1d4078] text-[#68696a] text-base font-normal font-['Cairo'] leading-normal"
                value={formData.name}
                onChange={e => setFormData({ ...formData, name: e.target.value })}
                required
              />
            </div>
          </div>

          {/* Phone Field */}
          <div className="flex flex-col justify-start items-start gap-3 flex-1">
            <div className="text-[#68696a] text-base font-normal font-['Cairo'] leading-normal">
              {t('address.phone')} *
            </div>
            <div className="w-full h-[54px] relative">
              <input
                className="w-full h-full px-4 py-2 rounded border border-[#dde2eb] focus:outline-none focus:border-[#1d4078] text-[#68696a] text-base font-normal font-['Cairo'] leading-normal"
                value={formData.phone}
                onChange={e => setFormData({ ...formData, phone: e.target.value })}
                required
              />
            </div>
          </div>
        </div>

        {/* Map Selection */}
        <div className="flex justify-start items-start gap-2">
          <div className="w-6 h-6 p-1.5 rounded border border-[#1d4078] flex justify-center items-center">
            <svg viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-[#1d4078]">
              <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
            </svg>
          </div>
          <div className="text-[#1e1e1e] text-base font-normal font-['Cairo'] leading-normal">
            {t('address.pickLocation')}
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-start items-start gap-3 w-full">
        <div className="flex justify-start items-start gap-5 w-full">
          {/* City Field */}
          <div className="flex flex-col justify-start items-start gap-3 flex-1">
            <div className="text-[#68696a] text-base font-normal font-['Cairo'] leading-normal">
              {t('address.city')} *
            </div>
            <div className="w-full h-[54px] relative">
              <select
                className="w-full h-full px-4 py-2 rounded border border-[#dde2eb] focus:outline-none focus:border-[#1d4078] text-[#68696a] text-base font-normal font-['Cairo'] leading-normal bg-white appearance-none"
                value={formData.city}
                onChange={e => setFormData({ ...formData, city: e.target.value })}
                required
              >
                <option value="">{t('select')}</option>
                {locations.map(location => (
                  <option key={location.id} value={location.id}>
                    {getLocalizedCityName(location)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Notes Field */}
          <div className="flex flex-col justify-start items-start gap-3 flex-1">
            <div className="text-[#68696a] text-base font-normal font-['Cairo'] leading-normal">
              {t('address.notes')}
            </div>
            <div className="w-full h-[54px] relative">
              <input
                className="w-full h-full px-4 py-2 rounded border border-[#dde2eb] focus:outline-none focus:border-[#1d4078] text-[#68696a] text-base font-normal font-['Cairo'] leading-normal"
                value={formData.notes || ''}
                onChange={e => setFormData({ ...formData, notes: e.target.value })}
              />
            </div>
          </div>
        </div>

        {/* Map Container */}
        <div className="w-full h-[323px] rounded-lg overflow-hidden border border-[#dde2eb]">
          <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY!}>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={{ lat: formData.lat, lng: formData.lng }}
              zoom={15}
              onClick={handleMapClick}
            >
              <Marker position={{ lat: formData.lat, lng: formData.lng }} />
            </GoogleMap>
          </LoadScript>
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex justify-between items-center w-full mt-4">
        <button
          onClick={onCancel}
          className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
        >
          {t('cancel')}
        </button>
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          {t('save')}
        </button>
      </div>
    </div>
  );
};

export default AddressAdd;
