import { BackButtom } from "components/BackButtom/BackButtom";
import ProfileCard from "components/ProfileCard/ProfileCard";
import React from "react";
import classes from "./AdressDetail.module.css";
import ADCard from "components/ADCard/ADCard";
import { useNavigate } from "react-router-dom";
import Layout from "shared/FaceBook/Layout";
import ParentComponent from "components/AddressCard/ParentComponent";
const AdressDetail = () => {
	const navigate = useNavigate();
	const checkIfUserIsLoggedIn = () => {
		const accessToken = localStorage.getItem("accessToken");
		const itemToken = localStorage.getItem("token");
		const isLoggedIn =
			(accessToken !== null && accessToken.length > 10) ||
			(itemToken !== null && itemToken.length > 10);
		if (!isLoggedIn) {
			navigate("/login");
		}

		return isLoggedIn ? accessToken : itemToken;
	};

	checkIfUserIsLoggedIn();
	return (
		<Layout>
			<div className="h-[30vh] w-[100vw] bg-[#005792]"></div>
			<div className="">

				<div className="container   translate-y-[-30vh] max-sm:translate-y-[-60vw]">
					<BackButtom />
					<div className="two mt-24 mb-5 min-h-[80vh] gap-[20px] bg-transparent  ">
						<ProfileCard />
						<ParentComponent />
						{/* <ADCard /> */}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default AdressDetail;
